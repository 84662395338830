import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import CampaignInfoPopup from '../CampaignInfoPopup/CampaignInfoPopup';
import RechargeCampaignPopup from '../RechargeCampaignPopup/RechargeCampaignPopup';
import SwitchButton from '../../../Custom/SwitchButton';
import { getCampaignLists } from '../../../../store/actions/Campaign/campaignListActions';
import GetCampaignDetailAPI from '../../../../services/Campaign/CampaignList/GetCampaignDetailAPI';
import PutToggleStatusAPI from '../../../../services/Campaign/CampaignList/PutToggleStatusAPI';
import GetCampaignListAPI from '../../../../services/Campaign/CampaignList/GetCampaignListAPI';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PRIMARY_COLOR, WHITE_BG } from '../../../../utils/CssConstants';
import { Refresh } from '@material-ui/icons';
import GetCardsFromBrandAPI from '../../../../services/Payment/GetCardsByBrandAPI';
import GetRechargeCampaignListAPI from '../../../../services/Campaign/CampaignList/GetRechargeCampaignListAPI';
import Helpers from '../../../../utils/Helpers';
import UpdateImage from '../../CreateCampaign/ImageUploader/UpdateImage';


const styles = {
    campaignListTDataRow: {
        display: 'flex',
        background: '#EDEEF7',
        border: '1px solid #000000',
        boxSizing: 'border-box',
        borderRadius: 10,
        padding: '10px',
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        '&:nth-child(even)': {
            backgroundColor: '#F7F7F7'
        }
    },
    campaingListData: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '0.90rem',
        padding: '5px 10px',
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        wordBreak: 'break-word'
    },
    campaignPhoto: {
        borderRadius: 10,
        width: 180,
        maxHeight: 180,
        objectFit: 'contain'
    },
    campaignName: {
        color: 'inherit',
        textDecoration: 'underline',
        cursor: 'pointer',
        "&:hover": {
            color: PRIMARY_COLOR,
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    },
    actionIcons: {
        "& > *": {
            marginRight: 15,
            cursor: 'pointer',
        }
    },
    smallSpace: {
        flex: 1
    },
    mediumSpace: {
        flex: 3
    },
    mediumSpaceTwo: {
        flex: 4
    },
    largeSpace: {
        flex: 7
    },
    campaignListBottom: {
        '&:before': {
            background: 'black'
        }
    },
    formSubmit: {
        background: PRIMARY_COLOR,
        color: WHITE_BG,
        border: 'none',
        borderRadius: 10,
        height: 45,
        width: 45,
        outline: 'none',
    },
    lowBudgetColor: {
        background: '#C5C5C5 !important'
    }
};

const TableData = ({ classes, campaignsData, seriesIndex, paginate, currentPage, selectedFilter, setTotalCampaigns, setTotalActiveCampaigns, setTotalInactiveCampaigns, setTotalExpiredCampaigns, setTotalRechargeCampaigns }) => {

    const [showModal, setModal] = useState(false);
    const [campaignInfo, setCampaignInfo] = useState("");
    const [campaignInfoError, setCampaignInfoError] = useState("");
    const [rechargeModal, setRechargeModal] = useState('');
    const [cards, setCards] = useState([]);


    const userDetails = useSelector((state) => state.authReducer.userDetails);
    const brandView = useSelector((state) => state.authReducer.brandView);

    const dispatch = useDispatch();

    const handleStatus = (campaignId, index) => {
        PutToggleStatusAPI(campaignId).then(data => {
            if (data.status === "SUCCESS") {
                toast.success(data.message)
                if (selectedFilter === 405) {
                    GetRechargeCampaignListAPI(currentPage, 10, (brandView ? userDetails.brand_id : null)).then(data => {
                        if (data !== undefined) {
                            dispatch(getCampaignLists(data.data.campaigns));
                            setTotalRechargeCampaigns(data.data.total_active);
                            if (index === 0 && campaignsData.length === 1 && currentPage !== 1) {
                                paginate(currentPage - 1)
                            }
                        }
                    }).catch(err => (err.response) && toast.error(err.response.data.error));
                    return
                }

                GetCampaignListAPI(currentPage, 10, selectedFilter, (brandView ? userDetails.brand_id : null)).then(data => {
                    if (data !== undefined) {
                        dispatch(getCampaignLists(data.data.campaigns));
                        setTotalCampaigns(data.data.total)
                        setTotalActiveCampaigns(data.data.total_active)
                        setTotalInactiveCampaigns(data.data.total_inactive)
                        setTotalExpiredCampaigns(data.data.total_expired)
                        setTotalRechargeCampaigns(data.data.total_recharge)
                        if (index === 0 && campaignsData.length === 1 && currentPage !== 1) {
                            paginate(currentPage - 1)
                        }
                    }
                }).catch(err => (err.response) && toast.error(err.response.data.error));
            }
        }).catch(err => (err.response) && toast.error(err.response.data.error));
    }

    const openCampaignInfo = (campaignId) => {
        GetCampaignDetailAPI(campaignId).then(data => {
            if (data !== undefined) {
                setCampaignInfo(data)
                setModal(true);
            }
        }).catch(err => (err.response) && setCampaignInfoError(err.response.data.error));
    }

    const openRechargeModal = campaign => {
        setCampaignInfo(campaign)
        setRechargeModal(true);
    }

    const returnCampaignStatus = (status) => {
        switch (status) {
            case 401:
                return 'New';
            case 402:
                return 'Active';
            case 403:
                return 'Inactive';
            case 404:
                return 'Draft';
            case 405:
                return 'Ready';
            case 406:
                return 'Expired';
            default:
                return 'Ready'
        }
    }




    useEffect(() => {
        GetCardsFromBrandAPI().then(data => {
            if (data !== undefined) {
                setCards(data);
            }
        }).catch(err => (err.response) && err.response.data.error);
    }, [])

    return (
        <>
            {(campaignsData.length > 0) && campaignsData.map((campaigns, index) =>
                <tr className={`${classes.campaignListTDataRow} ${campaigns.budget_remaining <= 0 && classes.lowBudgetColor}`} key={campaigns.campaign_id}>
                    <td className={`${classes.campaingListData} ${classes.smallSpace}`} id="table-index">#{seriesIndex + index}</td>
                    {userDetails.type_id !== 1806 && selectedFilter !== 406 &&
                        <td className={`${classes.campaingListData} ${classes.smallSpace}`} id="table-campaign-switch"><SwitchButton id="table-campaign-switch-icon" disabled={campaigns.status_id === 405 ? true : false} checked={campaigns.status_id === 402 ? true : false} handleChange={() => handleStatus(campaigns.campaign_id, index)} /></td>
                    }
                    <td className={`${classes.campaingListData} ${userDetails.type_id === 1802 || userDetails.type_id === 1806 ? classes.mediumSpace : classes.mediumSpaceTwo}`} id="table-campaign-name"><p className={classes.campaignName} onClick={() => openCampaignInfo(campaigns.campaign_id)}>{userDetails.type_id === 1802 || userDetails.type_id === 1806 ? campaigns.brand_name : campaigns.campaign_name}</p></td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpaceTwo}`} id="table-campaign-image">
                        <div>
                            {campaigns.image !== "" ? <img className={classes.campaignPhoto} src={campaigns.image} alt="" /> : "No Image"}
                            {userDetails.type_id !== 1806 &&
                                <UpdateImage id={campaigns.campaign_id} type_id={campaigns.type_id} campaignsData={campaignsData} />}
                        </div>
                    </td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpace}`} id="table-campaign-type">{campaigns.type_id !== "" && campaigns.type_id}</td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpace}`} id="table-budget">{campaigns.budget_total !== null && "$" + Helpers.numberWithCommas(campaigns.budget_total.toFixed(2))}</td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpace}`} id="table-budget-remaining">{campaigns.budget_remaining !== null && "$" + Helpers.numberWithCommas((campaigns.budget_remaining * (1 + campaigns.commission_charge)).toFixed(2))}</td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpace}`} id="table-status">{returnCampaignStatus(campaigns.status_id)}</td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpace}`} style={{ overflowWrap: 'break-word' }} id="table-session"><span>S: {Helpers.formatDate(campaigns.start_date + "Z", false)}<br />E: {campaigns.end_date === null ? 'Infinity' : Helpers.formatDate(campaigns.end_date + 'Z', false)}</span></td>
                    {userDetails.type_id !== 1806 && <td className={`${classes.campaingListData} ${classes.smallSpace}`} style={{ justifyContent: 'center' }}>
                        {(campaigns.status_id === 402) && <button className={classes.formSubmit} onClick={() => openRechargeModal(campaigns)}><Refresh titleAccess='Recharge Campaign' color={PRIMARY_COLOR} id="recharge-campaign" fontSize='40px' /></button>}
                    </td>}
                </tr >
            )}
            {
                showModal &&
                <CampaignInfoPopup campaignInfo={campaignInfo} campaignDetailError={campaignInfoError} closeModal={() => setModal(false)} />
            }
            {
                rechargeModal &&
                <RechargeCampaignPopup campaignInfo={campaignInfo} campaignDetailError={campaignInfoError} cards={cards} closeRechargeModal={() => setRechargeModal(false)} selectedFilter={selectedFilter} setTotalCampaigns={setTotalCampaigns} setTotalActiveCampaigns={setTotalActiveCampaigns} setTotalInactiveCampaigns={setTotalInactiveCampaigns} setTotalExpiredCampaigns={setTotalExpiredCampaigns} setTotalRechargeCampaigns={setTotalRechargeCampaigns} currentPage={currentPage} />
            }
        </>
    )
};

export default withStyles(styles)(TableData);